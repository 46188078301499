import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Pagination } from "swiper";
import "swiper/css/pagination";
import profilePic1 from "../../img/profile1.jpg";
import profilePic2 from "../../img/profile2.jpg";
import profilePic3 from "../../img/profile3.jpg";
import profilePic4 from "../../img/profile4.jpg";

const Testimonial = () => {
  const clients = [
    {
      img: profilePic1,
      review:
        "Compared to the other companies we have used in the past, Dot Developers has been far and above the best in every area. While building our new web site, the customer service has been prompt and straightforward and the actual services are well documented and logical to our needs.",
    },
    {
      img: profilePic2,
      review:
        "I would highly recommend Dot Developers. They are great to work with. The traffic to our website has increased thanks to their SEO program.",
    },
    {
      img: profilePic3,
      review:
        "We have been very happy with our new website! It looks professional and very easy to navigate. Our experience with the customer service at . Developers has been great. They handle things very efficiently and are available for any questions we have. They also keep us updated on monthly reports so we know how our site is doing. I would recommend . to anyone looking for website design or SEO work!",
    },
    {
      img: profilePic4,
      review:
        "I went to Thrive with a basic “feel” I wanted in a website. They helped flush out the visual layout and content. Dot DEvelopers services are bargain when you weigh the cost versus the product you receive. I will be using them again in future endeavors. Thanks again!",
    },
  ];

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        <span>Clients always get </span>
        <span>Exceptional Work </span>
        <span>from me...</span>
      <div className="blur t-blur1" style={{ background: "var(--purple)" }}></div>
      <div className="blur t-blur2" style={{ background: "skyblue" }}></div>

      </div>
      <Swiper
        // install Swiper modules
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                <span>{client.review}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;
